.message {
  @apply w-fit text-sm text-center text-base py-2 px-4 rounded-3xl leading-6;
  max-width: 338px;
  min-width: 120px;
  animation: show-ani 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  margin-bottom: 10px;
  background-color: #e7e7e7;
  color: #1a1a1a;
  &.closed {
    animation: hide-ani 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  & > * {
    @apply shrink-0;
  }
  .message-content {
    @apply flex-1 flex items-center justify-center;
  }
}
@keyframes show-ani {
  0% {
    transform: scale(0);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 50%;
  }
}

@keyframes hide-ani {
  0% {
    transform: scale(1);
    transform-origin: 50% 50%;
  }
  100% {
    transform: scale(0);
    transform-origin: 50% 50%;
  }
}

// 移动端
@media screen and (max-width: 767px) {
  .messagePhone {
    @apply text-xs;
  }
}
