.footer {
  width: calc(100%);
  padding-bottom: 20px;
  border-top: 1px solid #262525;
  margin-top: 70px;

  .footTop1 {
    @apply flex justify-center;

    .infoTitle {
      font-size: 20px;
      line-height: 20px;
      color: rgb(255, 255, 255);
      text-align: center;
      font-weight: 700;
      margin-top: 32px;
    }
  }

  .footTop {
    @apply flex justify-center;

    margin-top: 40px;
    .outer {
      @apply w-fit;
    }

    .inner {
      @apply w-fit;
      display: flex;
      justify-content: center;
      gap: 4rem;
      .img {
        @apply w-10 h-10 cursor-pointer inline-block;
        margin-right: 22px;

        & img {
          @apply w-full h-full;
        }
      }
    }

    .btn {
      width: 140px;
      height: 44px;
      background-color: #23252b;
      &.btnOne {
      }
      & > img.btnImg {
        @apply w-6 h-6;
      }
      & > img.webImg {
        width: 22px;
      }
      & > span {
        @apply text-base text-white;
      }
    }
  }

  .footBottom {
    margin-top: 20px;
    padding: 20px;
    & p {
      @apply text-sm opacity-60 text-white text-center;
      font-size: 12px;
    }

    & p:nth-child(2) {
      @apply my-3;
    }

    & p:last-child {
      @apply text-xs justify-center;
    }
  }

  .footBottom2 {
    padding: 20px;
    margin-top: 20px;
    & p {
      @apply text-sm opacity-60 text-white text-left;
      font-size: 12px;
    }

    & p:nth-child(2) {
      @apply my-3;
    }

    & p:last-child {
      @apply text-xs justify-center;
    }
  }
}

// 移动端
@media screen and (max-width: 767px) {
  .footer {
    margin-top: 40px !important;
    .footTop {
      @apply flex-col items-center;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .inner {
        gap: 0.4rem 1rem;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10%;
        .webImg {
          width: 100%;
        }
        .btn {
          @apply mt-3;
          width: 45%;
          &:first-child {
            width: 100%;
          }
          &:last-child {
            @apply mr-0;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 560px) {
//   .footer .footTop .inner {
//     flex-direction: column;
//     gap: 0;
//   }
// }
