.slide {
  @apply w-screen relative max-h-screen overflow-hidden;
  // height: 568px;
  height: 608px;
  &:hover {
    .switchLeft,
    .switchRight {
      width: 118px;
    }
  }

  .bg {
    @apply object-cover max-h-screen w-screen;
    background-size: center;

    height: 568px;
    height: 608px;
    width: 100%;
  }

  .bannerTab {
    @apply w-full z-4 left-0;
    bottom: 80px;
    background-color: red;
    padding: 20px;
    padding-left: 54px;
    margin-top: 120px;
    z-index: 9999;
  }

  .switch {
    @apply h-full flex items-center justify-center absolute top-0 z-3 w-0;
    transition: all 0.3s;

    & > img {
      @apply cursor-pointer;
      width: 19.14px;
      height: 33.05px;

      &:hover {
        content: url('/api/images/ar');
      }
    }

    &.switchLeft {
      @apply left-0;
      background: linear-gradient(to right, #212121 0%, rgba(33, 33, 33, 0) 100%);

      & > img {
        transform: rotate(-180deg);
      }
    }

    &.switchRight {
      @apply right-0;
      background: linear-gradient(to left, #212121 0%, rgba(33, 33, 33, 0) 100%);
    }
  }

  // 分页
  .pagination {
    @apply absolute z-3 w-full right-0 bottom-0 text-right;

    z-index: 10;
    width: calc(100% - 330px);
    // bg-linear-banner;

    height: 14%;
    //  background-color: red;
    // background: linear-gradient(to top, gray 0%, rgba(33, 33, 33, 0) 100%);
    .items {
      margin-right: 56px;
      display: flex;
      justify-content: right;
      .item {
        @apply inline-block bg-white opacity-100;
        // backdrop-filter: blur(80px);
        transition: width 0.3s;
        width: 100px;
        height: 60px;
        border-radius: 6px;
        margin-left: 8px;
        overflow: hidden;

        opacity: 0.5;
        max-width: 128px;
        max-height: 78px;
        img {
          height: 100%;
          width: 100%;
        }
        // background-color: #00c0ff;
        &.itemActive {
          // @apply w-4 opacity-100;
          // border:2px solid #f68104;
          backdrop-filter: blur(10px);
          opacity: 1;
        }
      }
    }
  }

  // 描述信息
  .desc {
    @apply absolute z-4;
    left: 56px;
    bottom: 80px;
    // background-color: #fff;

    .firstSeries {
      @apply text-white font-normal;
      font-size: 60px;
      font-family: STSongti-SC-Black;
      line-height: 60px;
      max-width: 680px;
    }

    .secondSeries {
      @apply text-white text-base overflow-hidden font-normal;
      font-size: 14px;
      max-width: 288px;
      padding-left: 2px;
      min-height: 120px;
      // height: 94px;
      margin-top: 40px;
    }

    .btnGroup {
      @apply mt-10;
      display: flex;
      align-items: center;
      .btn {
        @apply bg-white-10;
        color: #fff;
        width: 120px;
        height: 50px;
        border-radius: 33px;
        backdrop-filter: blur(10px);
        &:hover {
          @apply bg-white-60;
        }

        & > span {
          @apply font-semibold;
        }

        &.btnDetail {
          @apply bg-white-10 ml-4;
          .detalimg {
            width: 16px;
            height: 16px;
          }
          &:hover {
            @apply bg-white-40;
          }

          & > span {
            @apply text-white;
          }
        }
      }
    }
  }

  .leftMask {
    @apply h-full absolute left-0 top-0 z-2;
    width: 50%;
    background: linear-gradient(90deg, #000 0%, rgba(20, 20, 20, 0) 100%);
  }
}

// @media screen and (max-width: 1100px) {
//   .slide {
//     .mask {
//       .pagination {
//         width: 100%;
//       }
//       .desc {
//         bottom: 160px;
//         .secondSeries {
//           margin-top: 20px;
//           margin-bottom: 20px;
//         }
//       }
//     }
//   }
// }
.maskBottom {
  position: absolute;
  z-index: 3;
  -o-object-fit: cover;
  object-fit: cover;
  background-image: linear-gradient(
    179deg,
    rgba(17, 19, 25, 0) 1%,
    rgba(17, 19, 25, 0.05) 17%,
    rgba(17, 19, 25, 0.2) 31%,
    rgba(17, 19, 25, 0.39) 44%,
    rgba(17, 19, 25, 0.61) 56%,
    rgba(17, 19, 25, 0.8) 69%,
    rgba(17, 19, 25, 0.95) 83%,
    rgb(17, 19, 25) 99%
  );
  height: 36%;
  width: 100%;
  bottom: -20px;
}
// 移动端
@media screen and (max-width: 767px) {
  .slidePhone {
    @apply mt-24;
    height: 203px !important;

    .bg {
      @apply object-cover max-h-screen w-screen relative;
      background-size: center;
      height: 210px !important;
      // filter: brightness(96%);
    }

    .bannerTabPhone {
      @apply px-4 top-0 h-10 pt-2;
      background: linear-gradient(to top, rgba(20, 20, 20, 0) 0%, #141414 100%);
    }

    .switch {
      @apply hidden;
    }

    .mask {
      @apply relative;

      &::after {
        @apply absolute w-screen bottom-0 left-0 z-1;
        content: '';
        height: 160px;
        background: linear-gradient(to bottom, rgba(20, 20, 20, 0) 0%, #141414 100%);
      }

      // 描述信息
      .desc {
        @apply w-full  left-0  absolute z-4;

        bottom: 40px;
        margin-left: 1rem;
        .firstSeries {
          font-size: 22px;
          line-height: 30px;
          max-width: 100%;
        }

        .secondSeries {
          font-size: 12px;
          line-height: 18px;
          margin: 10px 0;
          min-height: 80px;
        }

        .btnGroup {
          margin-top: 10px;
          // display: none;
          .btn {
            width: 70px;
            height: 30px;

            &:hover {
              @apply bg-white;
            }

            & > img {
              @apply w-4 h-4;
            }

            & > span {
              @apply text-xs;
            }

            &.btnDetail {
              @apply ml-4;
              .detalimg {
                width: 12px;
                height: 12px;
              }
              &:hover {
                @apply bg-white-10;
              }
            }
          }
        }
      }

      // 分页
      .pagination {
        @apply h-10 text-center pt-2;
        z-index: 999;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 16px;
        width: 50%;
        .items {
          margin-right: 1rem;
          .item {
            @apply h-1 w-1 rounded ml-2;
            background-color: #00da5a;
            margin-left: 4px;
            &.itemActive {
              @apply w-1;
            }
          }
        }
      }
    }
  }
}
