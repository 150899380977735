@mixin activeItem() {
  @apply text-theme border;
  border-color: var(--theme);
  background-color: rgba(255, 128, 50, 0.2);
}
.switch {
  @apply flex;
  height: 30px;
  .item {
    @apply flex h-full items-center justify-center  border border-solid text-white-60 text-lg cursor-pointer;
    width: 74px;
    border-color: rgba(255, 255, 255, 0.4);
    & > img {
      @apply mr-1;
      width: 14px;
      height: 14px;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right-width: 0;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left-width: 0;
    }
    &.active1 {
      @include activeItem();
      & > img {
        content: url('/api/images/recommend-active');
      }
    }
    &.active2 {
      @include activeItem();
      & > img {
        content: url('/api/images/all-active');
      }
    }
  }
}

// 移动端
@media screen and (max-width: 767px) {
  .switchPhone {
    @apply h-6;
    .item {
      width: 60px;
      & > img {
        width: 10px;
        height: 10px;
      }
      & > span {
        @apply text-xs;
      }
    }
  }
}
